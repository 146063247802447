import React,{useEffect} from 'react';
import Footer from "../components/atoms/footer";
import Header from '../components/atoms/header';
// import Banner from "../components/molecules/Article/banner"
import ArticleDescription from '../components/molecules/Article/articleDescription'

import AOS from "aos";
import "aos/dist/aos.css";
function Services() {
  useEffect(() => {
    AOS.init({
      disable: 'mobile',
      duration : 1500
    });
  }, []);
    return (
      <>
        <main>
          <Header />
        {/* <Banner /> */}
        <ArticleDescription />
          <Footer/>
      </main>
      </>
    );
}

export default Services;